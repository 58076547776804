<template>
  <div style="text-align: left">
    <Card style="height:100%">
      <div class="search-con search-con-top">
        <Input clearable placeholder="输入应用名 搜索" class="search-input" v-model="queryinfo.name"
               @on-enter="namechange"
               @on-change="namechange"/>
        <Button @click="namechange" class="search-btn" type="primary">搜索</Button>
        <slot name="new_btn">
          <Button type="primary" class="search-btn"  @click="showaddmodel=true">新建</Button>
        </slot>
      </div>
      <div>
        <Table border ref="selection" :columns="columns" :data="data" ></Table>
      </div>

      <Modal
          v-model="showbindmodel"
          title="规则绑定"
          width="600"
          footer-hide>
        <div class="editor-container">
          <Form ref="formValidate" :model="formbindValidate" :rules="rulebindValidate" :label-width="90">
            <FormItem label="规则名" prop="rulename">
              <Select v-model="formbindValidate.rulename" placeholder="请选择绑定规则" clearable>
                <Option v-for="item in rulelist" :value="item.name"  :key="item.id" >{{ item.name }}</Option>
              </Select>
            </FormItem>
            <FormItem>
              <Button @click="handleSubmit('formValidate')" type="primary" >提交</Button>
              <Button @click="cancelbind" style="position: absolute;left: 15%;">取消</Button>
            </FormItem>
          </Form>
        </div>
      </Modal>

      <Modal
          v-model="showaddmodel"
          title="新建配置"
          width="600"
          footer-hide>
        <div class="editor-container">
          <Form ref="configValidate" :model="configdate" :rules="ruleconfigdata" :label-width="90">
            <FormItem label="配置分类" prop="category">
              <Select v-model="configdate.category" placeholder="请指定配置分类">
                <Option  v-for="item in configtype" :value="item.value" :key="item.index">{{ item.label }}</Option>
              </Select>
            </FormItem>
            <template v-if="configdate.category === 'type'">
              <FormItem label="配置类型" prop="paitype">
                <Input v-model="configdate.paitype"  placeholder="请输入配置类型：如task"></Input>
              </FormItem>
              <FormItem label="配置名称" prop="name">
                <Input v-model="configdate.name"  placeholder="请输入配置名称：如daai,kuaishou"></Input>
              </FormItem>
            </template>
            <template v-if="configdate.category === 'node'">
              <FormItem label="配置名称" prop="name">
                <Input v-model="configdate.name"  placeholder="请输入配置机器id"></Input>
              </FormItem>
            </template>
            <FormItem label="应用名" prop="app">
              <Input v-model="configdate.app"  placeholder="请输入应用名"></Input>
            </FormItem>
            <FormItem label="配置key	" prop="key">
              <Input v-model="configdate.key"  placeholder="请输入配置key"></Input>
            </FormItem>
            <FormItem label="配置值	" prop="config">
              <Input v-model="configdate.config" type="textarea" :autosize="{minRows: 2}" placeholder="请输入配置值"></Input>
            </FormItem>

            <FormItem>
              <Button @click="addhandleSubmit('configValidate')" type="primary" >提交</Button>
              <Button @click="selectSubmit('configValidate')" style="position: absolute;left: 15%;" type="primary" >查询</Button>
              <Button @click="cancel" style="position: absolute;left: 87%;">取消</Button>
            </FormItem>
          </Form>
        </div>
      </Modal>

      <Drawer
          :closable="false"
          v-model="showdetailsmodel"
          title="配置详情"
          width="600"
      >
        <h5 style="margin-top: 30px">应用配置维度</h5>
        <div class="alert-detail" style="padding: 5px 5px 20px;">
          <div class="alert-detail-row" v-for="(value, index) of detailsinfo" :key="index">
            <p>配置key_{{ value["id"] }}: {{ value["key"] }}</p>
            <div >
              {{ value["value"] }}
            </div>
            <Divider />
          </div>
        </div>
        <h5 style="margin-top: 30px">应用配置绑定规则</h5>
        <div class="alert-detail" style="padding: 5px 5px 20px;">
          <p>规则名称：</p>
          <div class="alert-detail-val">
            {{ bindrule }}
          </div>
        </div>
      </Drawer>

    </Card>
  </div>
</template>

<script>
import {get,post} from "@/api/http.js";
export default {
  name: "Config",
  data() {
    return {
      configtype: [
        {value: "node", label: 'node'},
        {value: "global", label: 'default'},
        {value: "type", label: 'type'}
      ],

      configdata: {
        "category":"",
        "paitype":"",
        "name":"",
        "app":"",
        "key":"",
        "auth":false,
        "config":"",
      },
      detailsinfo: [],
      showdetailsmodel: false,
      formbindValidate: {
        app:"",
        key:"",
        rulename: "",
      },

      configdate: {
        category:"",
        paitype:"",
        name:"",
        app:"",
        key:"",
        config: "",
      },
      bindrule: "",
      ruleconfigdata: {
        category: [{ required: true, message: '配置分类不能为空', trigger: 'blur' }],
        app: [{ required: true, message: '应用名不能为空', trigger: 'blur' }],
        key: [{ required: true, message: '配置key不能为空', trigger: 'blur' }],
        config: [{ required: true, message: '配置值不能为空', trigger: 'blur' }],
      },
      rulebindValidate: {
        rulename: [
          // { required: true, message: '规则名不能为空', trigger: 'blur' },
          {validator:this.validaterulename,trigger: 'blur'},
        ],
      },
      queryinfo: {},
      rulelist: {},
      showaddmodel: false,
      showbindmodel: false,
      columns: [
        {type: 'selection', width: 60, align: 'center'},
        {title: '应用名', key: 'app',},
        {title: '配置key', key: 'key'},
        {
          title: '操 作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showbind(params.row)
                  }
                }
              }, '规则绑定  '),
              h('a', {
                on: {
                  click: () => {
                    this.showdetails(params.row)
                  }
                }
              }, '详 情')
            ]);
          }
        }

      ],
      data: [],
    }
  },
  methods: {
    validaterulename(rule,value,callback){
      if (value === 'base_rule') {
        callback(new Error("该规则不需要绑定"))
      } else {
        callback()
      }
    },

    cancelbind(){
      this.formbindValidate = {app: "",key: "",rulename: ""}
      this.showbindmodel = false
    },

    cancel() {
      this.getdata(this.queryinfo)
      this.configdate = {category:"",paitype:"",name:"",app:"",key:"",config:""}
      this.showaddmodel = false
    },

    selectconfig(data){
      get("/etcd/api/v1/app/key", data)
          .then((resp)=>{
            if (resp.code === 0) {
              this.configdate.config = resp.data["storagedata"]["data"]["config"];
            } else {
              this.$Message.error(resp.msg);
            }
          })
          .catch((e)=>{
            return e
          })
    },

    postconfig(data) {
      post("/etcd/api/v1/app/key", data)
          .then((resp)=>{
            if (resp.code === 0) {
              this.$Message.success(resp.msg);
              this.cancel()
            } else {
              this.$Message.error(resp.msg);
            }
          })
          .catch((e)=>{
            return e
          })
    },

    putbind(data) {
      post("/etcd/api/v1/app/bind", data)
          .then((resp)=>{
            if (resp.code === 0) {
              this.$Message.success(resp.msg);
            } else {
              this.$Message.error(resp.msg);
            }
          })
          .catch((e)=>{
            return e
          })
      this.cancelbind()
    },

    showbind(row) {
      this.getrule()
      this.showbindmodel = true
      this.formbindValidate.app = row.app
      this.formbindValidate.key = row.key
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.putbind(this.formbindValidate)
        } else {
          this.$Message.error('请完善表单信息');
        }
      })
    },

    addhandleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.postconfig(this.configdate)
        } else {
          this.$Message.error('请完善表单信息');
        }
      })
    },

    selectSubmit(name) {
      this.selectconfig(this.configdate)
    },

    namechange() {},

    showdetails(row) {
      const queryinfo = {app: row.app,key: row.key}
      this.getdimension(queryinfo)
      this.getrulebind(queryinfo)
      this.showdetailsmodel = true
    },

    getdimension(queryinfo) {
      const url = "/etcd/api/v1/app/dimension"
      get(url,queryinfo)
          .then((resp)=>{
            if (resp.code === 0) {
              this.detailsinfo = resp["data"]
            } else {
              this.$Message.error(resp.data);
            }
          }).catch(e=>{
        return e
      })
    },

    getrulebind(queryinfo){
      const url = "/etcd/api/v1/app/bind"
      get(url,queryinfo)
          .then((resp)=>{
            this.bindrule = resp["data"]["rulename"]
          }).catch(e=>{
        return e
      })
    },

    getrule(){
      const url = "/etcd/api/v1/rule/list"
      get(url)
      .then((resp)=>{
        this.rulelist = resp["data"]
      }).catch(e=>{
        return e
      })
    },


    getdata(queryinfo) {
      const url = "/etcd/api/v1/app/all"
      get(url,queryinfo)
          .then((resp)=>{
            this.data = resp["data"];
          }).catch(e=>{
        return e
      })
    },
  },
  created() {
    this.getdata(this.queryinfo)
  }
}
</script>

<style lang="less" scoped>
.alert-detail {
  display: table;
}
.alert-detail-row {
  display: table-row;
  font-size: 12px;
}
.alert-detail-key,
.alert-detail-val {
  display: table-cell;
  padding: 5px 10px;
}


.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}

</style>
